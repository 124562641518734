import * as React from "react";
import Redirect from "../../../../components/redirect";
//--------------------------------------------------

  // beijing edition
  const url = "https://epaper.chinadaily.com.cn/a/202109/17/WS6143ceb6a3102c9543a160a3.html";

//--------------------------------------------------
// common config below this line, no need to change
const IndexPage = () => ( <Redirect goto_url={url} /> );
export default IndexPage;
